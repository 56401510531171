

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/rive-sud-montreal-prix-services-avocat-criminel-criminaliste.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost35 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Les avocats en droit criminel sur la Rive-Sud de Montréal - Soumissions Avocat"
        description="Vous avez été arrêté et des accusations pèsent contre vous? Votre jour devant le juge approche à grands pas et vous commencez à angoisser? Soumission Avocats vous trouve le meilleur avocat criminaliste sur la Rive-Sud de Montréal pour vous représenter!"
        image={LeadImage}>
        <h1>Les avocats en droit criminel sur la Rive-Sud de Montréal</h1>

					
					
						<p><strong>Vous avez été arrêté et faites l’objet d’accusations criminelles? </strong>Le cœur vous palpite et juste à l’idée de vous imaginer dans un habit orange derrière les barreaux? Le sentiment de panique est comprenable, mais prenez une grande respiration, un avocat en droit criminel sur la Rive-Sud de Montréal peut vous aider à vous en sortir!</p>
<p><strong>Quel que soit le type d’accusations auxquelles vous faites face, la solution passe par un avocat criminaliste. </strong></p>
<p><StaticImage alt="Engager services avocat criminaliste Rive-Sud Montréal" src="../images/rive-sud-montreal-prix-services-avocat-criminel-criminaliste.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Comme votre avenir est littéralement entre les mains de ce même juriste, il vaut mieux vous familiariser avec les grands principes du droit criminel afin de trouver l’avocat qui saura faire valoir vos droits. Vous ne savez pas par où commencer? Nous vous aidons justement à trouver les meilleurs avocats pour vous représenter sur la Rive-Sud!</p>
<h2>L’avocat criminaliste pourquoi est-il indispensable en cas d’accusations criminelles?</h2>
<p>Vous n’engageriez pas un architecte pour couler vos fondations ni un excavateur pour dessiner vos plans de maison et pourtant, les deux participent au processus de construction. Le même principe prévaut chez les avocats!</p>
<p>Afin de recevoir un service optimal, il vous <strong>faut engager un spécialiste de son domaine, chose d’autant plus importante dans un contexte d’accusations criminelles.</strong> En engageant un avocat criminaliste sur la Rive-Sud de Montréal, vous bénéficierez :</p>
<p><strong>D’une pratique spécialisée :</strong> Le droit criminel étant un monde appart, votre avocat se doit de connaitre les trucs qui ne s’enseignent pas sur les bancs d’école et seulement dans la pratique.</p>
<p><strong>De meilleures chances de succès :</strong> Au Canada, les causes allant jusqu’au bout d’un procès criminel ne connaissent qu’un<strong> taux d’acquittement de 4%. Plus de 60% </strong>se terminent <strong>verdicts de culpabilité</strong> et le reste se retrouve parmi les procédures retirées ou arrêtées. Devant des statistiques aussi inquiétantes, vaut mieux recevoir l’aide d’un expert, mais surtout du bon expert.</p>
<p><strong>D’un avocat habitué à négocier avec la couronne :</strong> Parfois, vos meilleures chances de succès se trouvent dans la négociation d’une entente avec la couronne. Cela consiste soit à <strong>abandonner certaines accusations</strong>, à plaider coupable en échange d’une peine réduite ou même à abandonner la poursuite du tout au tout. Ces négociations n’ont rien à voir avec les négociations contractuelles ordinaires; seul un criminaliste sait comment faire fléchir les genoux de la couronne!</p>
<p><strong>D’une juste évaluation de vos chances de succès :</strong> L’expérience étant un atout qui ne s’achète pas, un avocat criminaliste invétéré vous dira d’entrée de jeu quelles sont vos chances de succès en fonction de la preuve amassée contre vous et de la nature des accusations dont vous faites l’objet.</p>
<p><strong>Ne laissez rien au hasard, votre liberté pourrait en dépendre! Remplissez le formulaire de demande de soumission pour être mis en contact avec un avocat en droit criminel sur la Rive-Sud de Montréal!</strong></p>
<h2>Acte criminel ou infraction sommaire : une différence monstre!</h2>
<p>Avant de vous emballer et d’appuyer sur le bouton panique, prenez le temps de comprendre la nature des accusations dont vous faites l’objet. Il y a une importante distinction à faire en droit canadien entre les infractions sommaires et les actes criminels.</p>
<p><StaticImage alt="Les infractions sommaires et les actes criminels" src="../images/infraction-sommaire-accusations-criminelles-conseils-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Cette distinction se base principalement sur <strong>la gravité des gestes commis</strong> et leur impact dans la société. Il ne faut donc pas se surprendre que les conséquences varient elles aussi!</p>
<p><strong>Infractions sommaires :</strong></p>
<p>Les infractions sommaires sont celles que la loi considère comme étant d’une moindre gravité que les accusations criminelles. Les gestes moins graves sont traités différemment des crimes plus importants dans le but d’économiser les ressources de la justice et minimiser l’impact sur la vie de l’accusée.</p>
<p>Le <strong>processus d’infraction sommaire se veut plus rapide et moins exhaustif</strong> que l’acte criminel. Entre autres, il n’y a pas d’enquête préliminaire sur la preuve recueillie, vous êtes devant un juge seul et la peine maximale risquée est de 18 mois. Voici la liste non exhaustive des infractions sommaires prévues au Code criminel.</p>
<ul>
<li>Conduite avec les facultés affaiblies</li>
<li>Fraude et méfaits de moins de 5000$</li>
<li>Vol à l’étalage</li>
<li>Délit de fuite</li>
<li>Voies de fait mineur
</li>
</ul>
<p><strong>Actes criminels :</strong></p>
<p>Vous aurez vite compris qu’à l’inverse de l’infraction sommaire, l’acte criminel vise à punir les gestes les plus moralement répréhensibles en société. Même au sein des actes criminels on retrouve différents degrés de gravité des crimes commis.</p>
<p>Dans le cas d’un acte criminel, vous passerez soi devant un juge seul ou devant un jury si le crime est très grave (ex : meurtre), vous aurez droit à la tenue d’une enquête préliminaire afin de déterminer la suffisance de la preuve amassée contre vous.</p>
<p><strong>Le Code qualifie les gestes suivants comme des actes criminels qui enclenchent une procédure complète!</strong></p>
<ul>
<li>Extorsion</li>
<li>Meurtre</li>
<li>Traffic et possession en vue du Traffic de stupéfiants</li>
<li>Homicide involontaire</li>
<li>Vol qualifié</li>
<li>Voies de fait grave</li>
<li>Agression armée</li>
</ul>
<p><strong>Existe-t-il des infractions mixtes? </strong>Le droit n’étant pas une science exacte, il arrive que des gestes puissent tombent dans l’une ou l’autre deux catégories. On qualifiera alors ces gestes d’infractions mixtes, et il en reviendra au procureur de la couronne de choisir quel type d’accusations porter contre vous!</p>
<h2>La mise en accusation et les étapes du procès criminel : mieux vaut s’en sauver avec l’aide d’un avocat !</h2>
<p>Il suffit d’un mot de travers pour vous mettre les pieds dans de sérieux plats, alors faites bien attention de connaître et de respecter les étapes propres au procès criminel. De l’arrestation à l’acquittement ou à la condamnation, voici ce qui vous attend lorsque vous tombez entre les griffes de la justice!</p>
<p><StaticImage alt="Les étapes d’un procès criminel" src="../images/etapes-proces-criminel-accusations-conseils-avocat-criminalistes.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Arrestation par la police :</strong> À ce moment précis, vos droits ainsi que les motifs de votre arrestation doivent absolument vous être dits. Il s’agit de vos droits constitutionnels fondamentaux, au même titre que vous avez droit d’être représenté par un avocat.</p>
<p>Les policiers doivent même vous informer sur les services d’aide offerts en tout temps par les avocats de garde.</p>
<p><strong>La comparution devant un juge :</strong> La comparution sert à faire la lecture officielle à l’accusé des accusations qui seront portées contre lui. C’est à ce même moment que ce dernier enregistrera son plaidoyer de culpabilité ou de non-culpabilité.</p>
<p><strong>Audience de remise en liberté (pour certains crimes) :</strong> Si vous êtes accusé d’un crime grave, il y a de fortes chances que vous soyez détenus entre le moment de votre arrestation et l’audience de remise en liberté. Cette même audience sert justement à déterminer si vous représentez un danger pour la société. Comme vous êtes toujours présumé innocent, vous serez probablement libéré sous conditions dans le cas contraire.</p>
<p><strong>Rencontre avec le procureur couronne :</strong> Comme nous l’avons mentionné, l’avocat qui agit pour vous en défense a comme rôle celui de négocier en votre nom avec le procureur de la couronne. C’est à ce moment qu’une entente pourrait être conclue ou que, du moins, certaines accusations soient retirées de la poursuite.</p>
<p><strong>Enquête préliminaire :</strong> Réservée aux crimes les plus graves (acte criminels), l’objectif de l’enquête préliminaire vise à déterminer si le procureur de la couronne est parvenu à récolter assez de preuves pour justifier la tenue d’un procès. Dans le cas contraire, le juge se voit en droit de faire tomber la poursuite.</p>
<p><strong>Procès et verdict :</strong> Le procès sert évidemment à mettre en évidence la preuve amassée par la poursuite, mais également à permettre à la défense de réfuter cette preuve. Il en reviendra au juge ou au jury de décider de la culpabilité au-delà de tout doute raisonnable!</p>
<p><strong>Détermination de la peine:</strong> Une dernière et ultime phase vient clore le débat une fois pour toutes et il s’agit de la représentation sur la peine de l’individu trouvé coupable d’un crime en question. Le couronne et la défense débattront sur la durée de la peine la plus appropriée en fonction du crime commis, tout en tenant compte des critères propres à l’accusé lui-même.</p>
<h2>Accusations de conduite avec les facultés affaiblies : l’importance d’agir vite!</h2>
<p>Autant le Code criminel canadien que le Code de la sécurité routière prévoient de graves conséquences pour quiconque prend le volant avec les facultés affaiblies. Cette notion ne se limite d’ailleurs pas à l’alcool; elle comprend également le cannabis et autres drogues qui altèrent la capacité à conduire.</p>
<p>Il faut toutefois dire qu’au Canada, quiconque prend le volant avec un taux d’alcoolémie supérieur à 80 milligrammes d’alcool par 100 millilitres (le fameux 0.08) commet un crime. <strong>Si vous vous faites arrêter pour conduite en état d’ébriété, n’attendez pas avant d’appeler un avocat en droit criminel!</strong></p>
<p><strong><StaticImage alt="CONDUITE FACULTÉS AFFAIBLIES" src="../images/conduite-facultees-affaiblies-alcool-volant-accusations-representation-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></strong></p>
<p><strong>Vos droits en matière de conduite avec les facultés affaiblies.</strong> Les lois encadrant le contrôle des conducteurs en état d’ivresse se sont resserrées dans les dernières années pour octroyer plus de pouvoir aux policiers et leur permettre d’attraper plus de contrevenants.</p>
<p><strong>Les conséquences d’une condamnation pour conduite avec les facultés affaiblies. </strong>Si vous êtes déclaré coupable, vous aurez automatiquement un casier judiciaire et vous serez mis à l’amende. En cas de récidive ou de négligence grave, vous pourriez même risquer une peine d’emprisonnement.</p>
<p><strong>Toutefois, la condamnation pour de telles accusations n’a rien d’automatique et les avocats en droit criminel sur la Rive-Sud de Montréal savent comment vous sortir de l’eau chaude! </strong></p>
<h2>Violence conjugale, voies de fait et autres crimes violents : une expertise en soi</h2>
<p>La représentation de clients accusés de <strong>crimes violents</strong> nécessite une attention particulière des avocats criminalistes. En effet, ces crimes sont souvent rattachés d’un large éventail de preuves pour appuyer les accusations.</p>
<p>Il vous faudra donc un avocat qui sera capable de vous construire un argumentaire basé sur les défenses de droit criminel <strong>comme l’alibi, la défense de provocation, la légitime défense, la contrainte</strong> et autres justifications acceptées en matière criminelle.</p>
<p>Comme ces crimes sont ceux qui emportent les conséquences les plus lourdes, ne confiez pas votre défense à n’importe quel avocat sorti du bottin, confiez votre dossier à un véritable criminaliste!</p>
<h2>Fraude et vol : que risquez-vous comme conséquences?</h2>
<p>Aussi bien dans le cas d’accusations de fraude que de vol, c’est la valeur monétaire en jeu qui dictera les conséquences encourues. Lorsqu’une fraude est d’une valeur supérieure à 5000$, les conséquences peuvent aller <strong>jusqu’à 14 ans d’emprisonnement</strong> dans le pire des scénarios.</p>
<p><StaticImage alt="Conséquences fraude vol et crimes violents avocat" src="../images/fraude-vol-consequences-peine-minimale-emprisonnement.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>En revanche, la fraude d’une valeur inférieure à 5000$ vous expose à une sentence d’entre 6 mois et 2 ans, dépendamment de si elle est effectuée par procédure sommaire ou comme acte criminel.</p>
<p>Le vol est soumis aux mêmes principes que la fraude quant à la valeur de l’infraction, mais les peines varient entre un maximum de 10 ans pour les vols de 5000$ et moins et d’un maximum de 2 ans dans le cas contraire.</p>
<h2>Les crimes à caractères sexuels : une spécialisation nécessaire pour vous représenter</h2>
<p>Les accusations pour des <strong>crimes à caractère sexuel</strong> comprennent les actions indécentes comme l’exhibitionnisme, les agressions sexuelles de tous les degrés, la sollicitation et la prostitution, la pornographie juvénile et les contacts sexuels avec les mineurs. La plupart des infractions mentionnées ci-haut prévoient des peines minimales d’emprisonnement en cas de culpabilité.</p>
<p>Alors qu’en d’autres circonstances un juge peut ordonner une peine autre que l’emprisonnement, dans le cas d’un crime à caractère sexuel prévoyant une peine minimale, vous n’aurez pas d’autre choix que de purger une peine.</p>
<p>Si vous faites face à de telles accusations, être représenté par un avocat d’expérience ne fera que bénéficier à vos propres intérêts!</p>
<h2>Vous êtes accusé au criminel? Voici les réponses à vos questions les plus fréquentes!</h2>
<p>Que la télévision en soit la cause ou que les légendes urbaines aient pris trop de place dans l’imaginaire collectif, toujours est-il que le monde du droit criminel est empreint <strong>de mythes et de faussetés</strong>. La réalité du droit criminel est souvent bien différente de ce que l’on s’imagine. Nous nous mandatons donc pour répondre aux questions les plus fréquentes du milieu!</p>
<p><strong>Puis-je être poursuivi au civil en plus d’être accusé au criminel?

</strong>Une légende qui court depuis trop longtemps déjà veut qu’il soit impossible de poursuivre à la fois au civil et au criminel. Cela est complètement faux. Le système de justice civile et celui de justice criminelle sont indépendants.</p>
<p>Le premier vise la réparation du préjudice (souvent pour obtenir des dommages sous forme d’argent), alors que l’autre vise à punir l’auteur du crime. Pour illustrer le principe, une personne pourrait être déclarée innocente lors de son procès criminel, mais reconnue coupable lors d’un procès civil.</p>
<p>La raison? Le procès civil n’exige qu’une preuve basée sur <strong>la balance des probabilités</strong> (plus probable qu’improbable), alors que le procès criminel exige une preuve hors de tout doute raisonnable.</p>
<p><strong>
Comment décider si je dois plaider coupable ou non coupable?
</strong>
Vous devrez avoir de sérieuses discussions avec l’avocat chargé de votre défense pour prendre une telle décision. Celle-ci se basera évidemment sur l’étendue de la preuve que la couronne a bâtie contre vous, sur les risques d’une peine sévère en cas de verdict de culpabilité et la qualité de la défense que votre avocat est en mesure de produire.</p>
<p><StaticImage alt="Questions fréquentes avocat droit criminel" src="../images/questions-frequentes-accusations-criminelles-defenses-penales-avocat-prix.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>

Après combien de temps vais-je pouvoir demander un pardon?</strong></p>
<p>Si par malheur vous deviez être condamné et reconnu coupable, il existe tout de même une lueur d’espoir qui se trouve à être la demande de pardon! Dans le langage juridique, on l’appelle plutôt la demande de suspension du casier judiciaire.</p>
<p>Pour ce qui est du délai d’attente avant de pouvoir faire la demande de pardon, celui-ci variera en fonction de la gravité de l’infraction commise à l’origine et variera <strong>entre 5 et 10 ans</strong>.</p>
<p>Saviez-vous que les gens autrefois condamnés pour <strong>possession simple de cannabis</strong> peuvent obtenir un pardon automatique et accéléré en faisant la demande? Cela donne suite à la légalisation du cannabis à l’automne 2018!</p>
<p><strong>
Est-ce que mon procès aura lieu devant jury?</strong></p>
<p>Seuls les crimes les plus graves sont tenus devant un jury. Les crimes tels que les infractions sommaires, quant à elles, sont jugées devant un juge seul. Pour la plupart des actes criminels, l’accusé aura le choix entre le procès devant un juge seul ou devant un jury de ses pairs.</p>
<p>Il s’agit d’un droit protégé devant la constitution, qui prévoit que toute personne risque une peine de 5 ans et plus. À titre informatif, le verdict du jury composé de 12 personnes doit absolument être unanime. C’est donc une décision qui doit se prendre de concert avec son avocat à savoir s’il est préférable d’être jugé devant un juge seul ou un juge et jury.</p>
<p><strong>
Quel est le rôle de mon avocat lorsque je suis accusé au criminel?</strong></p>
<p>Votre avocat dans une cause criminelle est chargé de vous défendre, cela est évident. Il faut toutefois comprendre qu’en droit criminel, le fardeau de prouver la culpabilité de l’accusé repose uniquement sur les épaules du procureur de la couronne. C’est donc dire que votre avocat joue un rôle purement défensif qui consiste à réfuter les prétentions adverses.</p>
<p>Comme la poursuite doit convaincre le juge et/ou jury hors de tout doute raisonnable, votre avocat a donc pour rôle celui de semer le doute un peu partout dans la preuve de la poursuite.</p>
<p>Il ne faut pas oublier que votre avocat, dans un contexte aussi grave que celui d’accusations criminelles, est également votre principal conseiller. Une relation de confiance doit exister entre vous.</p>
<p><strong>
Je n’ai pas les moyens de me payer un avocat, vais-je devoir me représenter seul?</strong></p>
<p>N’ayez crainte, des programmes gouvernementaux d’aide juridique existent afin d’ouvrir les portes de la justice aux moins fortunés. Des seuils sont à respecter afin d’être éligible une telle aide, qui est normalement offerte gratuitement aux gens percevant un très faible revenu.</p>
<p>Il se peut également que vous soyez éligible à l’aide juridique, mais seulement au volet contributif! Cela vous permettra donc de recevoir une aide financière pour payer vos services légaux, ce qui vous permettra de payer moins cher pour de tels services.</p>
<h2>Accusé au criminel? Laissez Soumissions Avocat vous trouver un expert en droit criminel sur la Rive-Sud de Montréal!</h2>
<p>Vous êtes accusé au criminel et cherchez une solution pour vous en sortir? Il vous faut l’aide d’un avocat en droit criminel sur la Rive-Sud de Montréal! Seul un véritable juriste criminaliste comprend les rouages de l’appareil qu’est la justice criminelle et c’est justement l’allié qu’il vous faut pour maximiser les chances de sortir en homme libre de cette procédure.</p>
<p><strong>Vous vous sentez pris entre le marteau et l’enclume et vous commencez à angoisser? Restez zen et confiez à Soumissions Avocat la tâche de vous trouver l’avocat criminaliste qu’il vous faut.</strong></p>
<p><strong>En remplissant le formulaire, vous serez mis en contact avec les meilleurs avocats criminalistes sur la Rive-Sud de Montréal! N’attendez plus, la mise en contact est gratuite et ne vous engage à rien!</strong></p>
    </SeoPage>
)
export default BlogPost35
  